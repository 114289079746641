import React, { useState } from "react"
import StartJourney from "./StartJourney"
import ImageCarousel from "./ImageCarousel"
import { ConfigProvider } from "antd"
import PersonalDetails from "./PersonalDetails"
import SuggestedDevices from "./SuggestedDevices"
import { useNavigate, useLocation } from "react-router-dom"
import "./styles.css"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import { updateToken } from "../../redux/axios"
import ShippingAddress from "./ShippingAddress"
import { useSelector } from "react-redux"
import { EquipmentSatusOptions } from "./Types"

const SelfOnboardingWelcome = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    getEquipmentStatus,
    loading: equipmentStatusLoading,
    error: equipmentStatusError
  } = useSelector(state => state.getEquipmentStatus)
  const { loading: patientLoading, userInfo: patientInfoDetail } = useSelector(
    state => state.userInfo
  )
  const [currentStep, setCurrentStep] = useState(1)
  const [firstCall, setFirstCall] = useState(false)
  const [showStartJourney, setShowStartJourney] = useState(false); 
  const [isFromLastScreen, setIsfromLastScreen] = useState(false)

  const next = () => {
    setIsfromLastScreen(false)
    setCurrentStep(prev => prev + 1)
  }
  const prevPage = () => {
    if(currentStep === 4 && EquipmentSatusOptions[0].label.toLowerCase().trim() ===
    getEquipmentStatus?.status?.toLowerCase()?.trim()) { // if outpatient is in the shipping address and click on the back
      setIsfromLastScreen(true)
    }
    if (currentStep === 5) {
      if (
        EquipmentSatusOptions[1].label.toLowerCase().trim() ===
        getEquipmentStatus?.status?.toLowerCase()?.trim() || patientInfoDetail?.userType === "facility"
      ) {
        //this condition I added when user back press and that time we need to skip the shipping address as they will pick from clinic or facility patient
        setCurrentStep(prev => prev - 1)
      }
      setIsfromLastScreen(true)
    }
    setCurrentStep(prev => prev - 1)
  }

  if (currentStep === 4 && (patientInfoDetail?.userType === "facility" || EquipmentSatusOptions[1].label.toLowerCase().trim() ===
  getEquipmentStatus?.status?.toLowerCase()?.trim())) { //if user selected will pick from clinic then skip the shipping address or facility paitent
   next()
 }
  // useEffect(() => {
  //   if (!location?.state?.fromLogin) navigate("/login")
  // }, [location])

  useEffect(() => {
    setTimeout(() => {
      if (window.value !== undefined && window.token !== undefined && firstCall === false) {
        // getPatientData(window.value.patientId)
        setFirstCall(true)
        localStorage.setItem("token", JSON.stringify(window.token)) 
        sessionStorage.setItem("token", JSON.stringify(window.token)) 
      
        localStorage.setItem("id", JSON.stringify(window.patientId))
        sessionStorage.setItem("id", JSON.stringify(window.patientId))
        updateToken(window.token)
      }
      setShowStartJourney(true)   
      // if (!info?.isMobile && !welcomeTutorial) {
      //   navigate("/")
      // }
       //else if (!location?.state?.fromLogin && !window.isMobileVersion) navigate("/login")
      }, 1000)
  }, [window.token]) //eslint-disable-line

  // if (!info?.isMobile && !welcomeTutorial) return <></>
  return (
    <>
      <Helmet>
        <script>{`
                if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firstPage) {
                  window.webkit.messageHandlers.firstPage.postMessage(true);
                   } else {
                   console.error("window.webkit.messageHandlers.firstPage is undefined. Unable to post message.");
                 }
                function fillDetails(jsonData) {
                var content = JSON.parse(decodeURIComponent(jsonData))
                // var content = {"token":"eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJzZWxmIiwic3ViIjoiYjY0ZDdmYTYtZTNiNC00NzA0LThhYzItNDdmOTEwNzJhOGI1IiwiZXhwIjoxNzEzMzUyMDEwLCJpYXQiOjE3MTMzNDg0MTAsInNjb3BlIjoicGF0aWVudCJ9.xc5XjIcwnqjoJKKGwh2aCLo-_XhjE9dlnoAQqfUAo2LhjmPzoOuHwQLI5GXeFP44Nl_CF5AJdfZi83mnjcrozDQ3lbxJ2d6JwcfWx82rYUDFWkLNw9KqKDOAN746IjAYIoDzRFDS5ZOuCPSNVlmvq4CNZa9Bgs2iQ8ZYNvd24KBo6wrGB5zNyUmuGyvqT2CA3GIWSLX5s_d_jnac3LXkU067COzjNDRU_R5m56Sg1FLrc1M8WKKsGlhqq02wjEwo-pWZSdCyy02ldut8xri5RM5oimfgZIEf42FgKwO1IkR5ZorJuIAGI2dSAUA7pRK89ba5pqk_Tnl2y_6Z5jb_-Q", patientId:"b64d7fa6-e3b4-4704-8ac2-47f91072a8b5"};
                
                window.isMobileVersion = true;
                window.value = content;
                window.token = content.token;
                window.patientId = content.patientId
              }`}</script>
      </Helmet>

      <ConfigProvider
        theme={{
          components: {
            Input: { controlHeight: 48, borderRadius: 12, colorBgContainer: "#FBFBFB" },
            Select: { controlHeight: 48, borderRadius: 12, colorBgContainer: "#FBFBFB" },
            Button: { controlHeight: 48, borderRadius: 12, fontSize: 16 }
          }
        }}>

        <div className="selfonboarding-welcome">
          {currentStep === 1 && showStartJourney && <StartJourney next={next} patientId={location?.state?.patientId ?? window?.value?.patientId} />}
          {currentStep === 2 && (
            <ImageCarousel next={next} prevPage={prevPage} patientId={location?.state?.patientId ?? window?.value?.patientId} />
          )}
          {currentStep === 3 && (
            <PersonalDetails next={next} prevPage={prevPage} isFromLastScreen={isFromLastScreen} patientId={location?.state?.patientId ?? window?.value?.patientId} />
          )}
          {currentStep === 4 && (
            <ShippingAddress next={next} prevPage={prevPage} isFromLastScreen={isFromLastScreen} patientId={location?.state?.patientId ?? window?.value?.patientId} />
          )}
          {currentStep === 5 && (
            <SuggestedDevices prevPage={prevPage} patientId={location?.state?.patientId ?? window?.value?.patientId} />
          )}
        </div>
      </ConfigProvider>
    </>
  )
}

export default SelfOnboardingWelcome
