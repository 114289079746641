import axios from "axios"
import { Mixpanel } from "../utils/mixpanel"

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const token = JSON.parse(localStorage.getItem("token")  ?? sessionStorage.getItem("token"))
const refreshToken = localStorage.getItem("refreshToken") && JSON.parse(localStorage.getItem("refreshToken")) || undefined

// if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`

const instance = axios.create({
  timeout: 30000,
  baseURL: process.env.REACT_APP_BASE_URL || "",
  headers: {
    common: {
      "fp-timezone": timezone,
      Authorization: token ? `Bearer ${token}` : undefined
    }
  }
})

export function updateToken(token) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
const basicAuth =
  process.env.REACT_APP_BASIC_AUTH_USERNAME + ":" + process.env.REACT_APP_BASIC_AUTH_PASSWORD
const encodedToken = window.btoa(basicAuth)


const basicInstance = axios.create({
  timeout: 30000,
  baseURL: process.env.REACT_APP_BASE_URL || "",
  headers:{
    common:{
      "fp-timezone": timezone,
      Authorization: `Basic ${encodedToken}`
    }
  }
})

const refreshInstance = axios.create({
  timeout: 30000,
  baseURL: process.env.REACT_APP_BASE_URL || "",
  headers:{
    common:{
      "fp-timezone": timezone,
      Authorization: `Bearer ${refreshToken}`
    }
  }
})

const trackApiRequestInterceptor = (config) => {
  const urlLastSplited = config?.url?.split('/')
  const urlLastModified = urlLastSplited[urlLastSplited?.length - 1]
  Mixpanel.track(`API Sent ${urlLastModified}`, {
    url: config.url,
    method: config.method,
    data: config?.data ? { ...config.data } : {}
  });

  return config;
};

const trackApiResponseInterceptor = (response) => {
  const urlLastSplited = response?.config?.url?.split('/')
  const urlLastModified = urlLastSplited[urlLastSplited?.length - 1]
  Mixpanel.track(`API Response ${urlLastModified}`, {
    url: response.config.url,
    method: response.config.method,
    statusCode: response.status
  });

  return response;
};

instance.interceptors.request.use(trackApiRequestInterceptor)
instance.interceptors.response.use(trackApiResponseInterceptor)

basicInstance.interceptors.request.use(trackApiRequestInterceptor)
basicInstance.interceptors.response.use(trackApiResponseInterceptor)

refreshInstance.interceptors.request.use(trackApiRequestInterceptor)
refreshInstance.interceptors.response.use(trackApiResponseInterceptor)

// Make a request for a common post and get records
export const axiosGet = (url, config) => instance.get(url, config)
export const axiosPost = (url, payload, config) => instance.post(url, payload, config)

//common axios get and post request with basic auth.
export const axiosBasicGet = (url, config) => basicInstance.get(url, config)
export const axiosBasicPost = (url, payload, config) => basicInstance.post(url, payload, config)

//refresh Token
export const axiosRefreshGet = (url, config) => refreshInstance.get(url, config);