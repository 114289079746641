import React, { useState } from "react"

import { Card, Input, Row, Col, Button } from "antd"
import { useToasts } from "react-toast-notifications"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useDispatch, useSelector } from "react-redux"
import { passwordResetAction } from "../../redux/actions/userActions"
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Mixpanel } from "../../utils/mixpanel"
import FloatInput from "../../utils/FloatInput/FloatInput"
import PasswordValidation, {
  isMin8Chars,
  isMinOneNum,
  isMinOneSplChar
} from "../../utils/PasswordValidations"

const schema = yup
  .object({
    oldPassword: yup.string().required("Invalid Old Password"),
    newPassword: yup.string().required("Invalid Password"),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
  })
  .required()

const defaultValues = {
  newPassword: "",
  confirmPassword: ""
}

const Security = () => {
  const dispatch = useDispatch()
  const [apiError, setApiError] = useState(null)
  const { loading } = useSelector(state => state.passwordUpdate)
  const { addToast } = useToasts()

  const {
    reset,
    control,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const newPasswordValue = watch("newPassword")

  const onSubmit = async ({ oldPassword, newPassword }) => {
    const result = await dispatch(passwordResetAction({ oldPassword, newPassword }))
    if (result?.status === 200) {
      setApiError(null)
      reset()
      addToast("Password Changed Successfully", {
        appearance: "success",
        autoDismiss: true
      })
    } else {
      addToast(result?.message ?? result?.password, {
        appearance: "error",
        autoDismiss: true
      })
      setApiError(result?.message ?? result?.password)
    }
  }

  return (
    <Card title="">
      <h2 className="text-2xl">Security</h2>
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          <Col sm={24} lg={12}>
            <div className="mb-6">
              <Controller
                control={control}
                name="oldPassword"
                rules={{ required: true }}
                render={({ field }) => (
                 <FloatInput label="Old Password">
                  <Input.Password
                    required
                    size="large"
                    status={errors.oldPassword ? "error" : undefined}
                    {...field}
                    onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                    iconRender={(visible) =>
                      visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                    }
                  />
                 </FloatInput>
                )}
              />
              {errors.oldPassword ? (
                <small className="text-danger">{errors.oldPassword.message}</small>
              ) : null}
            </div>
            <div className="mb-6">
              <Controller
                control={control}
                name="newPassword"
                rules={{ required: true }}
                render={({ field }) => (
                 <FloatInput label="New Password">
                  <Input.Password
                    required
                    size="large"
                    status={errors.newPassword ? "error" : undefined}
                    {...field}
                    onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                    iconRender={(visible) =>
                      visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                    }
                  />
                 </FloatInput>
                )}
              />
              <PasswordValidation password={newPasswordValue}/>
            </div>
            <div className="mb-6">
              <Controller
                control={control}
                name="confirmPassword"
                rules={{ required: true }}
                render={({ field }) => (
                 <FloatInput label="Confirm New Password">
                  <Input.Password
                    required
                    size="large"
                    status={errors.confirmPassword ? "error" : undefined}
                    {...field}
                    onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                    iconRender={(visible) =>
                      visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                    }
                  />
                 </FloatInput>
                )}
              />
              {errors.confirmPassword ? (
                <small className="text-danger">{errors.confirmPassword.message}</small>
              ) : null}
            </div>

            {apiError && <p className="my-6 text-danger ">{apiError}</p>}

            <div>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                disabled={
                  !isMin8Chars(newPasswordValue) ||
                  !isMinOneSplChar(newPasswordValue) ||
                  !isMinOneNum(newPasswordValue)
                }
                onClick={() => { Mixpanel.track("Change Password Clicked") }}
              >
                Change Password
              </Button>
            </div>
          </Col>
          <Col sm={24} lg={12}>
            <img
              className="img-fluid"
              alt="password-illustration"
              src="/assets/password-illustration.png"
            />
          </Col>
        </Row>
      </form>
    </Card>
  )
}

export default Security
