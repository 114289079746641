import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { cgmDexcomCodeAction } from "../../redux/actions/cgmAction"

declare global {
  interface Window {
    isAppView?: boolean
    webkit
  }
}

const CGMCallBack = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [error, setError] = useState("")
  const [successfullySynced, setSuccessfullySynced] = useState(false)
  const patientId = localStorage.getItem("patientId") ?? ""
  const isMobileVersion = localStorage.getItem("isMobileVersion") === "true" ? true : false ?? false
  const { loading, cgmDexcomCode, error: ApiErr } = useSelector((state: any) => state.cgmDexcomCode)
  const { userInfo } = useSelector((state: any) => state.userInfo)

  useEffect(() => {
    if (isMobileVersion) {
      window.webkit?.messageHandlers?.cgmAuth?.postMessage({ value: "" })
    } else {
      successfullySynced && navigate("/vitals/glucose")
    }
  }, [successfullySynced])

  useEffect(() => {
    const getToken = async () => {
      const authorizationCode = new URLSearchParams(window.location.search).get("code")

      if (!authorizationCode) {
        console.error("Authorization code not found in the URL.")
        setError("Authorization code is not provided")
        return
      }
      // @ts-expect-error
      const result = await dispatch(
        cgmDexcomCodeAction({
          code: authorizationCode,
          patientId: isMobileVersion ? patientId : userInfo?.id
        })
      )

      if (result?.status === 200) {
        setSuccessfullySynced(true)
        setError("")
      } else {
        setError("An Error Occured, Try again")
        console.error("Error fetching access token:", error, ApiErr)
      }
    }

    getToken()
  }, [])

  return (
    <div>
      {error.length > 0 ? (
        <div>errors Processing..{patientId}</div>
      ) : (
        <div className="flex justify-center items-center h-screen bg-gray-100">
          <h2 className="text-2xl pt-0 font-semibold text-gray-800">Processing..{patientId}</h2>
        </div>
      )}
    </div>
  )
}

export default CGMCallBack
