import axios from "axios"

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const token = JSON.parse(localStorage.getItem("token") ?? sessionStorage.getItem("token"))

const instance = axios.create({
  timeout: 240000,
  baseURL: process.env.REACT_APP_BASE_URL || "",
  headers: {
    common: {
      timezone,
      Authorization: token ? `Bearer ${token}` : undefined
    }
  }
})

export const axiosLengthyGet = (url, config) => instance.get(url, config)
