import moment from "moment"
import { axiosGet, axiosPost } from "../axios"
import {
  GET_SELFONBOARD_ENROLSP_CODE_FAIL,
  GET_SELFONBOARD_ENROLSP_CODE_REQUEST,
  GET_SELFONBOARD_ENROLSP_CODE_SUCCESS,
  GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL,
  GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST,
  GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS,
  UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL,
  UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST,
  UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS
} from "../types/selfOnboardPatientConstant"
import { userLogoutAction } from "./userActions"

export const updatePatientWelcomeTutorial = patientId => async dispatch => {
  try {
    dispatch({
      type: UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST
    })
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token") ?? sessionStorage.getItem("token"))}`
      }
    }
    const data = await axiosPost(`/api/cs2/patients/welcomeTutorial/${patientId}/update`, {
      enrolledOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
    }, config)

    dispatch({
      type: UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 &&
      (!window.isMobileVersion
        ? dispatch(userLogoutAction())
        : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    console.log(error)
    dispatch({
      type: UPDATE_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const getPatientWelcomeTutorialAction = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/patients/welcomeTutorial/${patientId}/fetch`)

    dispatch({
      type: GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 &&
      (!window.isMobileVersion
        ? dispatch(userLogoutAction())
        : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    console.log(error)
    dispatch({
      type: GET_SELFONBOARD_PATIENT_WELCOME_TUTORIAL_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const getEnrolSpGeneratedCodeAction = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_SELFONBOARD_ENROLSP_CODE_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/patients/onBoarding/code/${patientId}/generate`)

    dispatch({
      type: GET_SELFONBOARD_ENROLSP_CODE_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 &&
      (!window.isMobileVersion
        ? dispatch(userLogoutAction())
        : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" }))
    console.log(error)
    dispatch({
      type: GET_SELFONBOARD_ENROLSP_CODE_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}
