import React from "react"

import { useSelector } from "react-redux"

import { Tabs } from "antd"
import { UserOutlined, SafetyOutlined, DeleteOutlined } from "@ant-design/icons"
import { MdOutlineFeedback } from "react-icons/md"

import Profile from "./Profile"
import Security from "./Security"
import DeleteAccount from "../DeleteAccount"
import Feedback from "./Feedback"
import packageJson from "../../../package.json"

const items = [
  {
    key: "1",
    label: (
      <div className="flex items-center fs-6">
        <UserOutlined className="pr-1"/>
        Profile
      </div>
    ),
    children: <Profile />
  },
  {
    key: "2",
    label: (
      <div className="flex items-center fs-6">
        <SafetyOutlined className="pr-1"/>
        Security
      </div>
    ),
    children: <Security />
  },
  {
    key: "3",
    label: (
      <div className="flex gap-2.5 items-center fs-6">
        <MdOutlineFeedback />
        Feedback
      </div>
    ),
    children: <Feedback />
  },
  {
    key: "4",
    label: (
      <div className="flex items-center fs-6">
        <DeleteOutlined className="pr-1"/>
        Delete Account
      </div>
    ),
    children: <DeleteAccount />
  }
]

const AccountComponent = () => {
  const { userInfo } = useSelector(state => state.userInfo)
  return userInfo ? (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={`Version: ${packageJson?.version}`}
      items={items}
    />
  ) : null
}

export default AccountComponent
