import { Col, Row, Slider } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPatientOxiCustomParameter } from "../../redux/actions/customParameterActions"
import Loading from "../layouts/Loading/Loading"

const PatientOxiProgressbar = ({ patientId, oxyData, }) => {
    const [spo2Values, setSpo2Values] = useState([0, 93, 100])
    const [marksSpo2, setMarksSpo2] = useState({})
    const [bpmValues, setBpmValues] = useState([10, 60, 100, 250])
    const [marksBpm, setMarksBpm] = useState({})
    const [spo2ValuePercent, setSpo2ValuePercent] = useState(0);
    const [bpmValuePercent, setBpmValuePercent] = useState(0);

    const [spo2StageWidth, setSpO2StageWidth] = useState({
        low: 50,
        normal: 50,
    });

    const [bpmStageWidth, setBpmStageWidth] = useState({
        low: 20,
        normal: 20,
        high: 20,
    });

    const dispatch = useDispatch();

    const { loading: OxiCustomParameterLoading, oxiCustomParameters } = useSelector(state => state.getPatientOxiCustomParameter)

    useEffect(() => {
        if (!OxiCustomParameterLoading) {
            if (oxiCustomParameters?.hasOwnProperty("id") && oxiCustomParameters?.hasOwnProperty("normalLowSPO")) {
                let customSpo2ValueArr = [];
                let customBpmValueArr = [];
                const spO2ValuesOrder = ["lowerLimitSPO", "normalLowSPO", "higherLimitSPO"]
                const bpmValueOrder = ["lowerPulseRate", "normalLowBPM", "normalHighBPM", "higherPulseRate"]
                for (let i = 0; i < spO2ValuesOrder.length; i++) {
                    customSpo2ValueArr.push(oxiCustomParameters && oxiCustomParameters[spO2ValuesOrder[i]])
                }
                setSpo2Values(customSpo2ValueArr);
                for (let j = 0; j < bpmValueOrder.length; j++) {
                    customBpmValueArr.push(oxiCustomParameters && oxiCustomParameters[bpmValueOrder[j]])
                }
                setBpmValues(customBpmValueArr)
            } else {
                setSpo2Values([0, 93, 100])
                setBpmValues([10, 60, 100, 250])
            }
        }
    }, [oxiCustomParameters])

    function calculatePercentage(start, end, minValue, maxValue) {
        const range = maxValue - minValue;
        const clampedEnd = Math.min(Math.max(end, minValue), maxValue);
        const percentage = ((clampedEnd - start) / range) * 100;
        return Math.min(Math.max(parseInt(percentage), 0), 100);
    }

    useEffect(() => {
        if (!OxiCustomParameterLoading) {
            dispatch(getPatientOxiCustomParameter(patientId))
        }
    }, [])

    useEffect(() => {
        let firstToSecondPoint = calculatePercentage(spo2Values[0], spo2Values[1], spo2Values[0], spo2Values[spo2Values.length - 1]);
        const secondToThirdPoint = calculatePercentage(spo2Values[1], spo2Values[2], spo2Values[0], spo2Values[spo2Values.length - 1]);

        setSpO2StageWidth({
            ...spo2StageWidth,
            low: firstToSecondPoint,
            normal: secondToThirdPoint,
        })
    }, [spo2Values])

    useEffect(() => {
        const firstToSecondPoint = calculatePercentage(bpmValues[0], bpmValues[1], bpmValues[0], bpmValues[bpmValues.length - 1]);
        const secondToThirdPoint = calculatePercentage(bpmValues[1], bpmValues[2], bpmValues[0], bpmValues[bpmValues.length - 1]);
        const thirdToFourthPoint = calculatePercentage(bpmValues[2], bpmValues[3], bpmValues[0], bpmValues[bpmValues.length - 1]);

        setBpmStageWidth({
            ...bpmStageWidth,
            low: firstToSecondPoint,
            normal: secondToThirdPoint,
            high: thirdToFourthPoint,
        })
    }, [bpmValues])

    useEffect(() => {
        const newSpO2value = spo2Values.slice(1, -1)
        const newMarksSpO2 = newSpO2value.reduce((acc, value, index) => {
            acc[value] = {
                label: (<>{value}</>),
                style: {
                    position: "absolute",
                    top: "-50px",
                    fontSize: "16px"
                }
            };
            return acc;
        }, {});
        setMarksSpo2(newMarksSpO2)
        const spO2ValPercent = calculatePercentage(spo2Values[0], oxyData?.[0]?.spo, spo2Values[0], spo2Values[spo2Values.length - 1])
        setSpo2ValuePercent(spO2ValPercent);
    }, [spo2Values])

    useEffect(() => {
        const newBpmvalue = bpmValues.slice(1, -1)
        const newMarksBpm = newBpmvalue.reduce((acc, value, index) => {
            acc[value] = {
                label: (<>{value}</>),
                style: {
                    position: "absolute",
                    top: "-50px",
                    fontSize: "16px"
                }
            };
            return acc;
        }, {});
        setMarksBpm(newMarksBpm)
        const bpmValPercent = calculatePercentage(bpmValues[0], oxyData?.[0]?.pulseRate, bpmValues[0], bpmValues[bpmValues.length - 1])
        setBpmValuePercent(bpmValPercent);
    }, [bpmValues])

    const trackColors = ["#ff3b30", "#34c759"]
    const pulseTrackColors = ["#ff3b30", "#34c759", "#ff3b30"]

    if (OxiCustomParameterLoading) {
        return <Loading />
    }

    return (
        <div>
            <div className="flex flex-col gap-4 mb-5">
                <div className="w-min border-[1px] border-current rounded py-4 text-center">
                    <h6 className="w-28 text-lg font-bold text-secondary mb-2">SpO2</h6>
                    <p className="font-bold text-4xl mb-0">
                        {oxyData?.[0]?.spo} %
                    </p>
                </div>
                <div className="relative">
                    <Slider
                        range
                        min={0}
                        max={100}
                        draggableTrack={false}
                        // onChange={setSpo2Values}
                        value={spo2Values}
                        marks={marksSpo2}
                        handleStyle={{ display: "none" }}
                        step={null}
                        dots={true}
                        dotStyle={{ borderColor: 'transparent', backgroundColor: 'transparent', width: 0, height: 0 }} // Hide dots
                        trackStyle={trackColors.map((color, index) => ({
                            backgroundColor: color
                        }))}
                    />
                    <div 
                        className="absolute top-[12px] rounded-full bg-[#0c66e4] cursor-pointer" 
                        style={{ 
                            width: "14px", 
                            height: "14px", 
                            left: `calc(${spo2ValuePercent}% - 7px)`,  // Subtract half the width to center
                            transform: 'translateX(0)',  // Reset any default transform
                        }}
                    />
                    <Row className="flex justify-between">
                        <Col className="flex justify-center items-center font-medium" style={{ width: `${spo2StageWidth.low}%` }}>Low</Col>
                        <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${spo2StageWidth.normal}%` }}>Normal</Col>
                    </Row>
                </div>
                <div className="w-min border-[1px] border-current rounded p-4 text-center">
                    <h6 className="text-lg font-bold text-secondary mb-2">BPM</h6>
                    <p className="font-bold text-4xl mb-0">{oxyData?.[0]?.pulseRate}</p>
                </div>
                <div className="relative">
                    <Slider
                        range
                        min={10}
                        max={250}
                        draggableTrack={false}
                        // onChange={setBpmValues}
                        value={bpmValues}
                        marks={marksBpm}
                        handleStyle={{ display: "none" }}
                        step={null}
                        dots={true}
                        dotStyle={{ borderColor: 'transparent', backgroundColor: 'transparent', width: 0, height: 0 }} // Hide dots
                        trackStyle={pulseTrackColors.map((color, index) => ({
                            backgroundColor: color
                        }))}
                    />
                    <div 
                        className="absolute top-[12px] rounded-full bg-[#0c66e4] cursor-pointer" 
                        style={{ 
                            width: "14px", 
                            height: "14px", 
                            left: `calc(${bpmValuePercent}% - 7px)`,  // Subtract half the width to center
                            transform: 'translateX(0)',  // Reset any default transform
                        }}
                    />
                    <Row className="flex justify-between">
                        <Col className="flex justify-center items-center font-medium " style={{ width: `${bpmStageWidth.low}%` }}>Low</Col>
                        <Col className="flex justify-center items-center font-medium " style={{ width: `${bpmStageWidth.normal}%` }}>Normal</Col>
                        <Col className="flex justify-center items-center font-medium " style={{ width: `${bpmStageWidth.high}%` }}>High</Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default PatientOxiProgressbar