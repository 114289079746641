import { Row, Col, Button, Input, Select } from "antd"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {
  getEmergencyContactDetailsV2Action,
  postEmergencyContactDetailV2Action,
  updateEmergencyContactDetailsV2Action
} from "../../../redux/actions/patientAction"
import { useEffect, useState, useRef } from "react"
import Loading from "../../layouts/Loading/Loading"
import formatPhoneNumber from "../../../utils/formatPhoneNumber"
import { PatternFormat } from "react-number-format"
import { PlusCircleFilled } from "@ant-design/icons"

const alphaRegex = /^[a-zA-Z\s]+$/

const contactSchema = yup.object().shape({
  emergencyMobNumber: yup
    .string()
    .nullable()
    .transform(value => (value === "" ? null : value))
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid Mobile Number"),
  EmergencyPersonName: yup
    .string()
    .trim()
    .nullable()
    .transform(value => (value === "" ? null : value))
    .matches(alphaRegex, "Name should contain alphabets only"),
  EmergencyPersonRelation: yup
    .string()
    .trim()
    .nullable()
    .transform(value => (value === "" ? null : value))
    .matches(alphaRegex, "Relationship should contain alphabets only")
})
const schema = yup.object().shape({
  dynamicForm: yup.array().of(contactSchema)
}).required()

const RelationshipOptions = [
  "Mother",
  "Father",
  "Spouse",
  "Child",
  "Sibling",
  "Grandparent",
  "Grandchild",
  "Aunt",
  "Uncle",
  "Nephew",
  "Niece",
  "Cousin",
  "Partner",
  "Friend",
  "Colleague",
  "Neighbor",
  "Guardian",
  "Other"
] 

const EmergencyContactDetail = ({ formData, setFormData, setCurrentStep }) => {
  const { loading, error } = useSelector(state => state.emergencyContactDetail)
  const [isFocused, setIsFocused] = useState(false)
  const [updateDetailsApiErr, setUpdateDetailsApiErr] = useState({})


  const {
    getEmergencyContactDetails,
    loading: emergencyContactDetailLoading,
    error: getEmergencyContactDetailError
  } = useSelector(state => state.getEmergencyContactDetails)

  const { loading: updateLoading, error: postNewDetailsApiErr } = useSelector(
    state => state.updateEmergencyContactDetails
  )
  const dispatch = useDispatch()  
  const initialEmptyValue = {
    dynamicForm: [
      {
        contactId: "",
        emergencyMobNumber: "",
        EmergencyPersonName: "",
        EmergencyPersonRelation: ""
      }
    ]
  };

  const defaultValues = initialEmptyValue;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const { fields, append } = useFieldArray({
    control,
    name: "dynamicForm"
  })

  const watchForm = watch("dynamicForm")

  const handleContactChange = (index, field, value) => {
    const currentContacts = [...(formData?.emergencyContacts || [])];
    if (!currentContacts[index]) {
      currentContacts[index] = {};
    }
    currentContacts[index][field] = value;
    
    setFormData(prevData => ({
      ...prevData,
      emergencyContacts: currentContacts
    }));
  };

  const isCurrentContactFilled = (index) => {
    const contact = watchForm[index];
    return (
      contact &&
      contact.emergencyMobNumber &&
      contact.EmergencyPersonName &&
      contact.EmergencyPersonRelation
    );
  };

  const canAddContact = () => {
    return fields.length < 3 && fields.every((_, index) => isCurrentContactFilled(index));
  };

  const onSubmit = async data => {
    // Save form data before submitting
    // setFormData(prevData => ({
    //   ...prevData,
    //   emergencyContacts: data.dynamicForm
    // }));

    // If no emergency contact details are provided, move to next step
    if (
      !data.dynamicForm?.[0]?.EmergencyPersonName?.length ||
      !data.dynamicForm?.[0]?.EmergencyPersonRelation?.length ||
      !data.dynamicForm?.[0].emergencyMobNumber?.length
    ) {
      setCurrentStep(prevStep => prevStep + 1); 
      return;
    }

    const toCreate = data.dynamicForm?.filter(contact => !contact?.contactId);
    const toUpdate = data.dynamicForm?.filter(contact => contact?.contactId);
    let flag = true;

    if (toCreate?.length) {
      // Handle new emergency contacts
      const formDataToPost = toCreate
        ?.filter(
          contact =>
            contact?.EmergencyPersonName && 
            contact?.EmergencyPersonRelation && 
            contact?.emergencyMobNumber
        )
        ?.map(contact => ({
          name: contact.EmergencyPersonName,
          relationship: contact.EmergencyPersonRelation,
          mobileNumber: contact.emergencyMobNumber?.replace(/[^\d]/g, ""),
          countryCode: "US"
        }));

      const res = await dispatch(postEmergencyContactDetailV2Action(formData.id, formDataToPost));
      if (res?.status !== 200) {
        flag = false;
      }
    }

    if (toUpdate?.length) {
      setUpdateDetailsApiErr(null)
      // Handle updating existing emergency contacts
      const updatePromises = toUpdate?.map(async contact => {
        const formDataToUpdate = {
          name: contact.EmergencyPersonName,
          relationship: contact.EmergencyPersonRelation,
          mobileNumber: contact.emergencyMobNumber?.replace(/[^\d]/g, ""),
          countryCode: "US"
        };
        
        const res = await dispatch(
          updateEmergencyContactDetailsV2Action(
            formData.id,
            contact.contactId,
            formDataToUpdate
          )
        );
        if (res?.status !== 200) {
          setUpdateDetailsApiErr(old => ({ ...old, ...res }))
          flag = false
        }
        
        if (res?.status !== 200) {
          flag = false;
        }
      });

      await Promise.all(updatePromises);
    }

    if (flag) {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  useEffect(() => {
    dispatch(getEmergencyContactDetailsV2Action(formData?.id))
  }, [])

  useEffect(() => {
    let mounted = true;
    
    if (!getEmergencyContactDetails?.length) {
      // Reset to empty form if no API data exists
      reset(initialEmptyValue);
      return;
    }
    
    if (mounted) {
      const mappedContacts = getEmergencyContactDetails.map(contact => ({
        contactId: contact.id,
        emergencyMobNumber: formatPhoneNumber(contact.mobileNumber) ?? "",
        EmergencyPersonName: contact.name ?? "",
        EmergencyPersonRelation: contact.relationship ?? ""
      }));

      reset({
        dynamicForm: mappedContacts
      });
    }

    return () => {
      mounted = false;
    };
  }, [getEmergencyContactDetails, reset]);

  useEffect(() => {
    // Dispatch an action to clear the error state
    dispatch({ type: 'UPDATE_EMERGENCY_CONTACT_DETAILS_RESET' });
  }, []);

  const prevPage = () => {
    dispatch({ type: 'UPDATE_EMERGENCY_CONTACT_DETAILS_RESET' });
    const currentFormData = watch('dynamicForm');
    setFormData(prevData => ({
      ...prevData,
      emergencyContacts: currentFormData.map(contact => ({
        contactId: contact.contactId || "",
        emergencyMobNumber: contact.emergencyMobNumber || "",
        EmergencyPersonName: contact.EmergencyPersonName || "",
        EmergencyPersonRelation: contact.EmergencyPersonRelation || ""
      }))
    }));
    setCurrentStep(prev => prev - 1);
  };

  window.back = () => {
    prevPage()
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleMobileChange = e => {
    const { value } = e.target
    return formatPhoneNumber(value)
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 100);
  };

  // Add this helper function near the top of the component
  const hasAnyContactFilled = (contacts) => {
    return contacts.some(contact => 
      contact.emergencyMobNumber &&
      contact.EmergencyPersonName &&
      contact.EmergencyPersonRelation
    );
  };

  // Add this ref to store input references
  const nameInputRefs = useRef({});

  const handleAddContact = () => {
    const newIndex = fields.length; // Get the index of the new field that will be added
    
    append({
      contactId: "",
      emergencyMobNumber: "",
      EmergencyPersonName: "",
      EmergencyPersonRelation: ""
    });
    
    scrollToBottom();
    
    // Focus the name input of the newly added contact after a short delay
    setTimeout(() => {
      const nameInput = nameInputRefs.current[newIndex];
      if (nameInput) {
        nameInput.focus();
      }
    }, 100); // Small delay to ensure the DOM has updated
  };

  return (
    <>
      {!emergencyContactDetailLoading ? (
        <div className="flex flex-col min-h-screen">
          <h1 className={`${!window?.isMobileVersion ? "pb-3" : "pb-8"} pt-4 text-center`}>
            Please enter emergency contact details
          </h1>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-grow h-[80vh] relative mb-4">
            <div className="flex-grow pb-4">
              {fields.map((field, index) => (
                <Row key={field.id} gutter={20} className="mb-4">
                  <Col xs={24} md={24} className="mb-4">
                    <label
                      className="text-[#959595] mb-[0.75rem] text-base"
                      htmlFor={`input-emergency-contact-name-${index}`}>
                      Name
                    </label>
                    <Controller
                      name={`dynamicForm.${index}.EmergencyPersonName`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          ref={(el) => {
                            nameInputRefs.current[index] = el;
                          }}
                          id={`input-emergency-contact-name-${index}`}
                          status={errors.dynamicForm?.[index]?.EmergencyPersonName ? "error" : undefined}
                          value={field.value}
                          className="bg-[#fff]"
                          placeholder="Enter Name"
                          onChange={e => {
                            // Only allow alphabets and spaces
                            const value = e.target.value;
                            if (/^[A-Za-z\s]*$/.test(value)) {
                              field.onChange(value);
                              handleContactChange(index, 'EmergencyPersonName', value);
                            }
                          }}
                          onKeyPress={(e) => {
                            // Prevent typing numbers and special characters
                            const char = String.fromCharCode(e.which);
                            if (!/^[A-Za-z\s]+$/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                    {errors.dynamicForm?.[index]?.EmergencyPersonName && (
                      <small className="text-danger">
                        {errors.dynamicForm[index].EmergencyPersonName.message}
                      </small>
                    )}
                  </Col>
                  <Col xs={24} md={12} className="mb-4">
                    <label
                      className="text-[#959595] mb-[0.75rem] text-base"
                      htmlFor={`input-emergency-mob-number-${index}`}>
                      Number
                    </label>
                    <Controller
                      name={`dynamicForm.${index}.emergencyMobNumber`}
                      control={control}
                      render={({ field }) => (
                        <PatternFormat
                          {...field}
                          format="(###) ###-####"
                          className={`formatted-mobile-input flex px-[11px] py-[12px] w-full rounded-xl border border-${
                            errors.dynamicForm?.[index]?.emergencyMobNumber
                              ? "danger"
                              : isFocused
                              ? "primary"
                              : "#dfdfdf"
                          } focus:outline-none`}
                          id={`input-emergencyMobNumber-${index}`}
                          value={field.value}
                          onChange={e => {
                            const formattedValue = handleMobileChange(e);
                            field.onChange(formattedValue);
                            handleContactChange(index, 'emergencyMobNumber', formattedValue);
                          }}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          placeholder="Enter Mobile Number"
                        />
                      )}
                    />
                    {errors.dynamicForm?.[index]?.emergencyMobNumber && (
                      <small className="text-danger">
                        {errors.dynamicForm[index].emergencyMobNumber.message}
                      </small>
                    )}
                  </Col>
                  

                  <Col xs={24} md={12} className="mb-4">
                    <label
                      className="text-[#959595] mb-[0.75rem] text-base"
                      htmlFor={`input-emergency-contact-relation-${index}`}>
                      Relationship
                    </label>
                    <Controller
                      name={`dynamicForm.${index}.EmergencyPersonRelation`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id={`input-emergency-contact-relation-${index}`}
                          status={
                            errors.dynamicForm?.[index]?.EmergencyPersonRelation ? "error" : undefined
                          }
                          value={field.value}
                          className="bg-[#fff] w-full"
                          placeholder="Select Relationship"
                          onChange={value => {
                            field.onChange(value);
                            handleContactChange(index, 'EmergencyPersonRelation', value);
                          }}
                        >
                          {RelationshipOptions.map(option => (
                            <Select.Option key={option} value={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.dynamicForm?.[index]?.EmergencyPersonRelation && (
                      <small className="text-danger">
                        {errors.dynamicForm[index].EmergencyPersonRelation.message}
                      </small>
                    )}
                  </Col>
                  {updateDetailsApiErr?.[field?.contactId] ? (
                  <Col className="mb-2" xs={24}>
                    <p className="text-danger font-semibold">
                      {updateDetailsApiErr?.[field?.contactId]}
                    </p>
                  </Col>
                ) : null}
                </Row>
              ))}
              

              {fields.length < 3 && (
                <Button
                  type="default"
                  onClick={handleAddContact}
                  block
                  icon={<PlusCircleFilled />}
                  className={`mb-4 ${
                    canAddContact()
                      ? 'add-contact-enabled'
                      : 'add-contact-disabled'
                  }`}
                  disabled={!canAddContact()}
                  style={{
                    borderColor: canAddContact() ? '#1890ff' : '#d9d9d9',
                    color: canAddContact() ? '#1890ff' : 'rgba(0, 0, 0, 0.25)',
                  }}
                >
                  Add more emergency contact
                </Button>
              )}

            
            </div>

            <div className="mt-auto pt-4 pb-6">
              <Row className="w-full mb-4" gutter={20}>
                <Col xs={12} md={12}>
                  <Button
                    type="primary"
                    onClick={prevPage}
                    style={{ minWidth: 0 }}
                    className="w-full">
                    Back
                  </Button>
                </Col>
                <Col xs={12} md={12}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading || updateLoading}
                    style={{ minWidth: 0 }}
                    className="w-full">
                    {watchForm?.[0]?.EmergencyPersonName &&
                    watchForm?.[0]?.emergencyMobNumber &&
                    watchForm?.[0]?.EmergencyPersonRelation
                      ? "Next"
                      : "Skip"}
                  </Button>
                </Col>
              </Row>
            </div>
          </form>
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default EmergencyContactDetail
