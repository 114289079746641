import React, { useState, useEffect } from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { Input, Button, Checkbox, Form } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { userLoginAction, clearUserLoginError } from "../../redux/actions/userActions"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import LoginWithLandline from "./LoginWithLandline"
import { EyeInvisibleOutlined, EyeOutlined, LockOutlined } from "@ant-design/icons"
import { Mixpanel } from "../../utils/mixpanel"
import FloatInput from "../../utils/FloatInput/FloatInput"
import LoginAttemptsMsg from "./LoginAttemptsMsg"

const INVALID_CREDS = "Enter a Valid Email or Phone Number"
const INVALID_PASS = "Enter Valid Password"

const schema = yup
  .object({
    credential: yup.string().required(INVALID_CREDS),
    password: yup.string().required(INVALID_PASS)
  })
  .required()

const LoginComponent = ({ userName }) => {
  const defaultValues = {
    credential: userName || "",
    password: ""
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showLandline, setShowLandline] = useState(false)
  const [disableLogin, setDisableLogin] = useState(false)
  // State for Remember Me checkbox
  const [rememberMe, setRememberMe] = useState(false)

  const { loading: authorizeLoading, error: authorizeErr } = useSelector(state => state.userInfo)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  // Effect to check and set remembered credential
  useEffect(() => {
    const remembered = localStorage.getItem('rememberMe') === 'true';
    if (remembered) {
      setRememberMe(remembered);
    }
  }, []);

  const onSubmit = async ({ credential, password }) => {
    // Handle Remember Me functionality
    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
    }
    // Pass rememberMe state to login action
    await dispatch(userLoginAction(credential, password, null, navigate, rememberMe))
  }

  useEffect(() => {
    if (authorizeErr) setValue("password","")
  }, [authorizeErr, setValue])

  return (
    <>
      <LoginAttemptsMsg setDisableLogin={setDisableLogin} />
      {showLandline ? (
        <LoginWithLandline
          setShowLandline={setShowLandline}
          disableLogin={disableLogin}
          setDisableLogin={setDisableLogin}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Controller
              name="credential"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  className="h-[48px]"
                  status={errors.credential ? "error" : undefined}
                  {...field}
                  disabled={true}
                />
              )}
            />

            {errors.credential ? (
              <small className="text-danger">{errors.credential.message}</small>
            ) : null}
          </div>
          <div className="mb-3">
            <p
              className="text-center text-secondary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowLandline(true)
                dispatch(clearUserLoginError())
                setDisableLogin(false)
              }}>
              Login with landline
            </p>
          </div>

          <div className="min-h-[65px]">
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FloatInput label="Password">
                  <Input.Password
                    className="h-[48px]"
                    prefix={<LockOutlined className="text-[#96A0B5]" />}
                    status={errors.password ? "error" : undefined}
                    {...field}
                    onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                    iconRender={visible => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
                    disabled={disableLogin}
                  />
                </FloatInput>
              )}
            />
            {errors.password ? (
              <small className="text-danger">{errors.password.message}</small>
            ) : null}
          </div>

          <div className="flex justify-between items-center mb-3">
          {/* Remember Me checkbox */}
              <Form.Item className="mb-0">
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(!rememberMe)}
              >
                Remember me
              </Checkbox>
            </Form.Item>
            <NavLink
              to="/forget-password"
              className="text-primary no-underline"
              onClick={() => Mixpanel.track("Forgot Password Clicked")}
            >
              Forgot Password?
            </NavLink>
          </div>

          <Button
            block
            className="h-[48px]"
            type="primary"
            htmlType="submit"
            loading={authorizeLoading}
            disabled={disableLogin}
            onClick={() => {
              Mixpanel.track("Login Clicked", { Email: userName })
            }}>
            Login
          </Button>

          {authorizeErr !== undefined && typeof authorizeErr === "string" && (
            <p className="mt-3 text-danger text-center font-medium">{authorizeErr}</p>
          )}
        </form>
      )}
    </>
  )
}

export default LoginComponent