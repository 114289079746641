import React, { useState, useEffect } from "react"

import { Card, Input, Row, Col, Radio, Button } from "antd"

import { useDispatch, useSelector } from "react-redux"

import { useToasts } from "react-toast-notifications"
// import imageCompression from "browser-image-compression"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
// import { base64ImageConvertor } from "../layouts/utils/imageConvertor"
import { UpdateProfileAction } from "../../redux/actions/userActions"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { PatternFormat } from "react-number-format"
import PersonalDetails from "./PersonalDetails"

const MAX_MOBILE_ERR = "Mobile Number Must Be 10 Digits"
const INVALID_EMAIL_ERR = "Invalid email"

const schema = yup.object({
  email: yup
    .string()
    .email()
    .matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, INVALID_EMAIL_ERR)
    .optional(),
  mobileNumber: yup
    .string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, MAX_MOBILE_ERR)
    .optional()
})

const Profile = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { userInfo } = useSelector(state => state.userInfo)
  const { loading } = useSelector(state => state.profileUpdate)

  const [apiError, setApiError] = useState("")
  const [isFocused, setIsFocused] = useState(false)

  const {
    control,
    setValue,
    // setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async data => {
    const { email, mobileNumber } = data

    const result = await dispatch(
      UpdateProfileAction({
        ...userInfo,
        userName:
          userInfo.userName ||
          `${userInfo?.firstName}${userInfo?.middleName || ""}${userInfo?.lastName}`,
        middleName: userInfo?.middleName || null,
        email: email || null,
        mobileNumber: mobileNumber?.replace(/[^\d]/g, "") || null,
        countryCode: userInfo?.countryCode?.toUpperCase() ?? "US"
      })
    )
    if (result?.status === 201) {
      addToast("Update Profile Successful", { appearance: "success", autoDismiss: true })
      setApiError("")
    } else setApiError(result)
  }

  useEffect(() => {
    if (userInfo?.email) {
      setValue("email", userInfo?.email)
    }
    if (userInfo?.mobileNumber) {
      setValue("mobileNumber", formatPhoneNumber(userInfo?.mobileNumber))
    }
  }, [userInfo]) //eslint-disable-line

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleMobileChange = e => {
    const { value } = e.target
    return formatPhoneNumber(value)
  }

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} lg={8}>
        <PersonalDetails />
      </Col>
      <Col sm={24} lg={16}>
        <Card title="">
          <div className="flex items-center justify-between gap-4 flex-wrap mb-3">
            <h2 className="text-2xl">Contact Details</h2>
          </div>

          <form className="p-3 rounded" onSubmit={handleSubmit(onSubmit)}>
            <Row gutter={16}>
              <Col sm={24} md={24} className="mb-2 w-full">
                <label htmlFor="email" className="mb-2">
                  Email
                </label>
                <Controller
                  control={control}
                  name="email"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      status={errors.email ? "error" : undefined}
                      size="large"
                      id="email"
                      {...field}
                      onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                    />
                  )}
                />
                {errors.email ? (
                  <small className="text-danger block">{errors.email.message}</small>
                ) : null}
              </Col>
              <Col sm={24} md={24} className="mb-2 w-full">
                <label htmlFor="mobileNumber" className="mb-2">
                  Mobile Number
                </label>
                <Controller
                  name="mobileNumber"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    const { ref, ...rest } = field
                    return (
                      <PatternFormat
                        format="(###) ###-####"
                        className={`formatted-mobile-input flex px-[11px] py-[7px] text-[1rem] w-full  border ${
                          errors.mobileNumber
                            ? "border-danger"
                            : isFocused
                            ? "border-primary"
                            : "border-[#d9d9d9]"
                        }  rounded-lg focus:outline-none`}
                        id="input-mobileNumber"
                        {...rest}
                        onChange={e => rest.onChange(handleMobileChange(e))}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                    )
                  }}
                />
                {errors.mobileNumber ? (
                  <small className="text-danger block">{errors.mobileNumber.message}</small>
                ) : null}
              </Col>

              {apiError ? (
                <Col className="mt-4" xs={24}>
                  <p className="text-danger font-medium">{apiError}</p>
                </Col>
              ) : null}
            </Row>
            <div className="text-right">
              <Button
                className="mt-3"
                size="large"
                type="primary"
                htmlType="submit"
                disabled={loading}>
                {loading ? "Updating..." : "Update"}
              </Button>
            </div>
          </form>
        </Card>
      </Col>
    </Row>
  )
}

export default Profile
