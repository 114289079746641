import {
    GET_LATEST_PCM_CAREPLAN_REQUEST,
    GET_LATEST_PCM_CAREPLAN_SUCCESS,
    GET_LATEST_PCM_CAREPLAN_FAIL
} from "../types/pcmConstant";
import { axiosGet } from "../axios"
import { userLogoutAction } from "./userActions"


export const getLatestPcmCareplanActions = (patientId) => async dispatch => {
    try {
        dispatch({
            type: GET_LATEST_PCM_CAREPLAN_REQUEST
        })
        const { data } = await axiosGet(`/api/pcm/careplan/principalCare/latest/${patientId}/fetch`)

        dispatch({
            type: GET_LATEST_PCM_CAREPLAN_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_LATEST_PCM_CAREPLAN_FAIL,
            payload: error.response && error?.response?.data?.detail
        })
    }
}
