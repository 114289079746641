import React from "react"

import { Card, Row, Col, Button } from "antd"

import { useSelector } from "react-redux"

const DeleteAccount = () => {
  const { userInfo } = useSelector(state => state.userInfo)

  const handleClick = () => {
    const toAddress = encodeURIComponent("help@fitpeo.com")
    const body = encodeURIComponent(
      `Hi FitPeo,\n\nPlease Delete my account that was assocated with the below details.\n\nEmail: ${userInfo?.email}`
    )
    const subjesdct = `Deletion of the account`
    window.location.href = `mailto:${toAddress}?subject=${subjesdct}&body=${body}`
  }

  return (
    <Card title="">
      <h2 className="text-2xl">Delete Account</h2>
      <Row gutter={16}>
        <Col sm={24} lg={12}>
          <p className="my-4 text-xl">
            If you want to delete your FitPeo account, simply click on the delete button below to
            proceed. Our team will assist you throughout the process to ensure that your account is
            deleted securely and effectively.
          </p>
          <Button size="large" type="primary" onClick={handleClick}>
            Delete Account
          </Button>
        </Col>
        <Col sm={24} lg={12}>
          <img alt="delete-account" className="img-fluid" src="/assets/delete-account.png" />
        </Col>
      </Row>
    </Card>
  )
}

export default DeleteAccount
