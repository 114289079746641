import {
    GET_LATEST_PCM_CAREPLAN_REQUEST,
    GET_LATEST_PCM_CAREPLAN_SUCCESS,
    GET_LATEST_PCM_CAREPLAN_FAIL,
} from "../types/pcmConstant";

export const getLatestPcmPlanReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_LATEST_PCM_CAREPLAN_REQUEST:
            return { loading: true }
        case GET_LATEST_PCM_CAREPLAN_SUCCESS:
            return { loading: false, latestPcmCareplan: action.payload }
        case GET_LATEST_PCM_CAREPLAN_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
