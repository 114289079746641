import React, { useState, useEffect } from "react"

import { Layout, Grid, ConfigProvider } from "antd"

import _ from "lodash"
import { useToasts } from "react-toast-notifications"

import store from "../redux/store"
import { useDispatch, useSelector } from "react-redux"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import { axiosGet } from "../redux/axios"
import Loading from "../components/layouts/Loading/Loading"

import "./main-layout.css"
import { userLogoutAction } from "../redux/actions/userActions"
import { getPatientMonitoringData } from "../redux/actions/patientAction"
import { Mixpanel } from "../utils/mixpanel"

const { useBreakpoint } = Grid
const { Content, Footer } = Layout
// const { defaultAlgorithm, darkAlgorithm } = theme

const MainLayout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [sidebarHidden, setSidebarHidden] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  const dispatch = useDispatch()
  const screens = useBreakpoint()
  const isMobile = !screens?.lg

  const { addToast } = useToasts()
  const { userInfo } = useSelector(state => state.userInfo)

  // const { isDark } = useSelector(state => state.themeConfig)

  useEffect(() => {
    if (isMobile) {
      setSidebarHidden(true)
    } else {
      setSidebarHidden(false)
      setSidebarCollapsed(false)
    }
  }, [isMobile])

  useEffect(() => {
    const storedUser = localStorage.getItem("userInfo");
    const uId = localStorage.getItem('id') ?? sessionStorage.getItem('id');
    const token = localStorage.getItem('token') ?? sessionStorage.getItem('token');
    if (uId && token) {
      setIsLoading(true)
      axiosGet(`/api/cs/patients/${JSON.parse(uId)}/profile`).
        then(res => {
          if (!_.isEqual(JSON.parse(storedUser), res.data.details)) {
            store.dispatch({
              payload: res?.data?.details,
              type: "USER_LOGIN_SUCCESS"
            })
            localStorage.setItem("userInfo", JSON.stringify(res?.data?.details))
            Mixpanel.identify(uId)

            Mixpanel.people.set({
              "ID": uId,
              "Type": res?.data?.details?.userType,
              "UserName": res?.data?.details?.userName,
              "$name": `${res?.data?.details?.firstName} ${res?.data?.details?.middleName ?? ""} ${res?.data?.details?.lastName}`,
              "$email": res?.data?.details?.email,
              "orgID": res?.data?.details?.orgId
            })
            Mixpanel.register({
              "ID": uId,
              "Type": "patient",
              "UserName": res?.data?.details?.userName,
              "Email": res?.data?.details?.email,
              "orgID": res?.data?.details?.orgId
            })
          }
          dispatch(getPatientMonitoringData(res?.data?.details?.id))
          setIsLoading(false)
        })
    }
  }, [])

  // useEffect(() => {
  //   if (isDark) {
  //     document.body.classList.add("dark-theme")
  //   } else {
  //     document.body.classList.remove("dark-theme")
  //   }
  // }, [isDark])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#007aff"
        }
        // algorithm: isDark ? darkAlgorithm : defaultAlgorithm
      }}>
      <Layout className="main-layout">
        <Sidebar
          sidebarHidden={sidebarHidden}
          sidebarCollapsed={sidebarCollapsed}
          setSidebarHidden={setSidebarHidden}
          setSidebarCollapsed={setSidebarCollapsed}
        />
        <Layout>
          <Navbar 
            sidebarHidden={sidebarHidden}
            sidebarCollapsed={sidebarCollapsed}
            setSidebarHidden={setSidebarHidden}
            setSidebarCollapsed={setSidebarCollapsed}
          />
          <Content>{isLoading ? <Loading /> : children}</Content>
          <Footer style={{ textAlign: "center" }}>
            <h6> &#169; {new Date().getFullYear()}, FitPeo Inc. All Rights Reserved</h6>
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export default MainLayout
