import { capitalize } from "lodash"

export const formatProfileType = profileType => {
  if (/caretaker/i.test(profileType)) return "Caretaker"
  else if (/enrollment/i.test(profileType)) return "Enrollment"
  else if (/admin/i.test(profileType)) return "Admin"
  else if (/provider|practitioner/i.test(profileType)) return "Provider"
  else if (/patient/i.test(profileType)) return "Patient"
  else if (/operations/i.test(profileType)) return "Super Admin"
  else return capitalize(profileType)
}
