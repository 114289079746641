import React, { useEffect } from "react"

import { Card, Input, Button, Select } from "antd"
import { useToasts } from "react-toast-notifications"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useDispatch, useSelector } from "react-redux"
import { postFeedbackAction, resetPostFeedbackErrAction } from "../../redux/actions/userActions"
import { Mixpanel } from "../../utils/mixpanel"

const schema = yup
  .object({
    feedbackType: yup.string().required("Required Feedback Type"),
    feedbackDescription: yup.string().required("Required Feedback")
  })
  .required()

const defaultValues = {
  feedbackType: "",
  feedbackDescription: ""
}

const Feedback = () => {
  const dispatch = useDispatch()

  const { userInfo } = useSelector(state => state.userInfo)
  const { loading, status, error: ApiErr } = useSelector(state => state.postFeedback)

  const { addToast } = useToasts()

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async data =>
    await dispatch(
      postFeedbackAction({
        userId: userInfo?.id,
        feedBackType: data?.feedbackType,
        feedBack: data?.feedbackDescription
      })
    )

  useEffect(() => {
    if (status?.isSuccess) {
      reset()
      dispatch(resetPostFeedbackErrAction())
      addToast("Feedback Submitted Successfully", {
        appearance: "success",
        autoDismiss: true
      })
    }
  }, [status])

  useEffect(() => {
    dispatch(resetPostFeedbackErrAction())
  }, [])

  return (
    <Card title="">
      <h2 className="text-xl mb-2">Provide Feedback</h2>
      <p>Tell us what you love or what we could be doing better.</p>
      <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6 flex items-center gap-3 flex-wrap">
          <label htmlFor="input-feedback-type" className="font-semibold">
            Select Feedback Type
          </label>
          <Controller
            name="feedbackType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                id="input-feedback-type"
                className="w-[200px]"
                status={errors.feedbackType ? "error" : undefined}
                options={[
                  {
                    value: "suggestion",
                    label: "Suggestion"
                  },
                  {
                    value: "error",
                    label: "Error"
                  }
                ]}
                {...field}
              />
            )}
          />
          {errors.feedbackType ? (
            <small className="text-danger">{errors.feedbackType.message}</small>
          ) : null}
        </div>

        <div className="mb-6">
          <label htmlFor="input-feedback" className="mb-1 font-semibold">
            Enter Feedback
          </label>
          <Controller
            control={control}
            name="feedbackDescription"
            rules={{ required: true }}
            render={({ field }) => (
              <Input.TextArea
                id="input-feedback"
                placeholder="Type here..."
                size="large"
                status={errors.feedbackDescription ? "error" : undefined}
                {...field}
              />
            )}
          />
          {errors.feedbackDescription ? (
            <small className="text-danger">{errors.feedbackDescription.message}</small>
          ) : null}
        </div>

        {ApiErr ? <p className="mb-4 text-danger font-semibold">{ApiErr}</p> : null}

        <div className="text-center">
          <Button
            size="large"
            className="w-[180px]"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={() => {
              Mixpanel.track("Feedback Submit Clicked")
            }}>
            Submit
          </Button>
        </div>
      </form>
    </Card>
  )
}

export default Feedback
