import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { decryptBoolean } from "../../utils/encryptionUtils"

const AuthGuard = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = localStorage.getItem("token") ?? sessionStorage.getItem("token");
  const userId = localStorage.getItem("id") ?? sessionStorage.getItem("id");
  const welcomeTutorial = localStorage.getItem("welcomeTutorial") ?? sessionStorage.getItem("welcomeTutorial")

  useEffect(() => {
    if (!token && !userId || !welcomeTutorial ) {
      navigate("/login", { replace: true })
    }
    else if(decryptBoolean(JSON.parse(welcomeTutorial)))
    {
      navigate("/web/welcome")
    } 
  }, [])

  return children
}

export default AuthGuard
