import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Button, Skeleton } from "antd"
import { getUserProfileAction, userLogoutAction } from "../../../redux/actions/userActions"
import { getPatientWelcomeTutorialAction } from "../../../redux/actions/selfOnboardPatientAction"
import { decryptBoolean } from "../../../utils/encryptionUtils"
import Loading from "../../layouts/Loading/Loading"
import drImg from "../../../assets/self-onboarding-doctor.png"
import FitPeoLogo from "../../../assets/fitpeo_logo.png"
import ImageCarouselWeb from "./ImageCarouselWeb"

const StartJourneyWeb = ({ next, patientId }) => {
  const token = JSON?.parse(localStorage.getItem("token") ?? sessionStorage.getItem("token"))
  patientId = JSON?.parse(localStorage.getItem("id") ?? sessionStorage.getItem("id"))
  const welcomeTutorialString = localStorage.getItem("welcomeTutorial") ?? sessionStorage.getItem("welcomeTutorial")
  const [providerImg, setProviderImage] = useState(drImg)
  const [showImgModal, setShowImgModal] = useState(false)
  const [loadingProviderImg, setLoadingProviderImg] = useState(true)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, userInfo: patientInfoDetail } = useSelector(state => state.userInfo)
  const {
    loading: welcomeTutorialLoading,
    data: userWelcomeTutorial,
    error
  } = useSelector(state => state.getSelfOnboardedPatient)

  useEffect(() => {
    dispatch(getUserProfileAction(patientId))
    dispatch(getPatientWelcomeTutorialAction(patientId))
  }, [])

  useEffect(() => {
    if (userWelcomeTutorial?.orgImage === null) {
      // if there is no image uploaded by sup admin
      setProviderImage(drImg)
    } else {
      setProviderImage(userWelcomeTutorial?.orgImage)
    }
  }, [userWelcomeTutorial])

  if (!token && !patientId) {
    dispatch(userLogoutAction())
  }

  if (!welcomeTutorialString) {
    navigate("/")
  }
  const welcomeTutorial = JSON.parse(welcomeTutorialString)
  if (!decryptBoolean(welcomeTutorial)) {
    navigate("/")
    // Return null to render nothing
    return null
  }

  const onImageLoadHandler = () => {
    setLoadingProviderImg(false)
  }

  return (
    <div className="bg-[#f5f6fa] h-screen">
      <div className="px-6 py-3">
        <img src={FitPeoLogo} width={122} alt="fitpeo logo" />
      </div>
      <div
        style={{ height: "calc( 100vh - 122px )" }}
        className="flex items-start justify-center p-3">
        <div
          style={{
            boxShadow: "0px 4px 40px 4px rgba(185, 210, 255, 0.32)"
          }}
          className="bg-[#fff] min-w-[60%] min-h-[90%] rounded-[12px] flex items-center justify-center p-6">
          {!welcomeTutorialLoading && !loading && patientInfoDetail ? (
            <div className="flex flex-wrap items-center w-full justify-center gap-x-16">
              <div className="w-[450px]">
                <h1 className="text-center">
                  Welcome to FitPeo,{" "}
                  <span className="capitalize">{patientInfoDetail?.firstName}</span>!
                </h1>
                <p className="secondary text-justify">
                  <span className="capitalize">{patientInfoDetail?.orgName}</span> is here to
                  welcome you to your own healthcare program. They have chosen FitPeo as your
                  personalized healthcare team, focused on giving you better care using
                  Bluetooth-enabled medical devices.
                </p>
                <Button
                  className="mt-8"
                  block
                  type="primary"
                  disabled={error}
                  onClick={() => setShowImgModal(true)}>
                  Start Your Health Journey
                </Button>
              </div>

              {loadingProviderImg && <Skeleton.Avatar size={240} active />}

              <img
                className="my-8 rounded-full"
                style={{ width: loadingProviderImg ? 0 : 240 }}
                loading="lazy"
                src={providerImg}
                alt="doctor"
                onLoad={onImageLoadHandler}
              />
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      {showImgModal && (
        <ImageCarouselWeb
          next={next}
          open={showImgModal}
          closeModal={() => setShowImgModal(false)}
        />
      )}
    </div>
  )
}

export default StartJourneyWeb
