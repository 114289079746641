import { axiosGet } from "../axios"
import {
  CGM_DEXCOM_CODE_FAIL,
  CGM_DEXCOM_CODE_REQUEST,
  CGM_DEXCOM_CODE_SUCCESS
} from "../types/cgmConstant"
import { userLogoutAction } from "./userActions"

export const cgmDexcomCodeAction = ({code, patientId}) => async dispatch => {
  try {
    if(!patientId) return
    dispatch({
      type: CGM_DEXCOM_CODE_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/dexcom/${patientId}/code/${code}`)
    dispatch({
      type: CGM_DEXCOM_CODE_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 &&
      (!window?.isMobileVersion
        ? dispatch(userLogoutAction())
        : window.webkit?.messageHandlers?.logout?.postMessage({ value: "" }))
    dispatch({
      type: CGM_DEXCOM_CODE_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}
