export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"
export const USER_LOGIN_RESET = "USER_LOGIN_RESET"

export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST"
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS"
export const GOOGLE_LOGIN_FAIL = "GOOGLE_LOGIN_FAIL"
export const GOOGLE_LOGIN_RESET = "GOOGLE_LOGIN_RESET"

export const SEARCH_UNIDENTIFY_REQUEST = "SEARCH_UNIDENTIFY_REQUEST"
export const SEARCH_UNIDENTIFY_SUCCESS = "SEARCH_UNIDENTIFY_SUCCESS"
export const SEARCH_UNIDENTIFY_FAIL = "SEARCH_UNIDENTIFY_FAIL"

export const PASSWORD_UPDATE_REQUEST = "PASSWORD_UPDATE_REQUEST"
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS"
export const PASSWORD_UPDATE_FAIL = "PASSWORD_UPDATE_FAIL"
export const USER_LOGOUT = "USER_LOGOUT"
export const VALIDATE_LOGIN_DETAILS_REQUEST = "VALIDATE_LOGIN_DETAILS_REQUEST"
export const VALIDATE_LOGIN_DETAILS_SUCCESS = "VALIDATE_LOGIN_DETAILS_SUCCESS"
export const VALIDATE_LOGIN_DETAILS_FAIL = "VALIDATE_LOGIN_DETAILS_FAIL"
export const VALIDATE_LOGIN_DETAILS_RESET = "VALIDATE_LOGIN_DETAILS_RESET"

// patient profile
export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST"
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS"
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL"

// profile pic upload
export const IMAGE_UPLOAD_REQUEST = "IMAGE_UPLOAD_REQUEST"
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS"
export const IMAGE_UPLOAD_FAIL = "IMAGE_UPLOAD_FAIL"
export const IMAGE_UPLOAD_RESET = "IMAGE_UPLOAD_RESET"

export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST"
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS"
export const GET_ADMIN_FAIL = "GET_ADMIN_FAIL"

// email update
export const EMAIL_UPDATE_REQUEST = "EMAIL_UPDATE_REQUEST"
export const EMAIL_UPDATE_SUCCESS = "EMAIL_UPDATE_SUCCESS"
export const EMAIL_UPDATE_FAIL = "EMAIL_UPDATE_FAIL"

// phone number update
export const PHONE_UPDATE_REQUEST = "PHONE_UPDATE_REQUEST"
export const PHONE_UPDATE_SUCCESS = "PHONE_UPDATE_SUCCESS"
export const PHONE_UPDATE_FAIL = "PHONE_UPDATE_FAIL"

export const CONFIRM_SIGNUP_REQUEST = "CONFIRM_SIGNUP_REQUEST"
export const CONFIRM_SIGNUP_SUCCESS = "CONFIRM_SIGNUP_SUCCESS"
export const CONFIRM_SIGNUP_FAIL = "CONFIRM_SIGNUP_FAIL"

export const POST_FEEDBACK_REQUEST = "POST_FEEDBACK_REQUEST"
export const POST_FEEDBACK_SUCCESS = "POST_FEEDBACK_SUCCESS"
export const POST_FEEDBACK_FAIL = "POST_FEEDBACK_FAIL"
export const POST_FEEDBACK_RESET = "POST_FEEDBACK_RESET"
