import React, { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import {
  AuthContext,
  AuthProvider,
  TAuthConfig,
  TRefreshTokenExpiredEvent
} from "react-oauth2-code-pkce"
import ClipLoader from "react-spinners/ClipLoader"
import { updateToken } from "../../redux/axios"

const CLIENT_ID = "dHwMLRJ4mTpXLRGbEriX6asXgNiPgOI8"
const AUTH_URL = "https://sandbox-api.dexcom.com/v2/oauth2/login"
const TOKEN_URL = "https://sandbox-api.dexcom.com/v2/oauth2/token"
const REDIRECT_URI = "https://dexacom---patientportal-dkit4d6yya-el.a.run.app/cgmCallback"

const authConfig: TAuthConfig = {
  clientId: CLIENT_ID,
  authorizationEndpoint: AUTH_URL,
  tokenEndpoint: TOKEN_URL,
  redirectUri: REDIRECT_URI,
  scope: "offline_access",
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
    event.logIn(undefined, undefined, "popup")
}

declare global {
  interface Window {
    token?: string
    value?: any
    patientId?: string
    isMobileVersion?: boolean
  }
}

const UserInfo = () => {
  const { token, tokenData, logIn } = useContext(AuthContext)

  useEffect(() => {
    window.addEventListener("message", handleMessageFromiOS)

    const timer = setTimeout(() => {
      if (
        localStorage.getItem("isMobileVersion") === "false" ||
        localStorage.getItem("isMobileVersion") === null
      ) {
        logIn()
      }
    }, 3000)
    return () => {
      window.removeEventListener("message", handleMessageFromiOS)
      clearTimeout(timer)
    }
  }, [])

  const handleMessageFromiOS = event => {
    if (event.data && event.data.source === "iOS") {
      localStorage.setItem("token", JSON.stringify(event.data.message.token))
      localStorage.setItem("patientId", event.data.message.patientId)
      localStorage.setItem("isMobileVersion", "true")
      logIn()
    }
  }

  return (
    <div className="flex items-center justify-center h-screen">
      <ClipLoader color="#0034cf" />
    </div>
  )
}

const CGMAuthentication = () => (
  <>
    <AuthProvider authConfig={authConfig}>
      <UserInfo />
    </AuthProvider>
  </>
)

export default CGMAuthentication
