import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

interface CGMProps {
  isAuthenticated: boolean
}

const CGMView = () => {
  const navigate = useNavigate()
  const { patientMonitoringData } = useSelector((state: any) => state.patientMonitoringData)


  useEffect(() => {
    const isCgmauthorized =
      patientMonitoringData?.rpm?.some(({ programs }) =>
        programs?.some((item: any) => item.cgmauthorized === true)
      ) || false

    !isCgmauthorized ? navigate("/cgmLogin") : navigate("/vitals/glucose")
  }, [])

  return <div></div>
}

export default CGMView
