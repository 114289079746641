import React from "react"

import { Row, Col, Input, Button, Spin, Divider, Carousel, Typography } from "antd"

import { useDispatch, useSelector } from "react-redux"
import {
  googleLoginAction,
  validateLoginDetailsAction,
  confirmSignupAction,
  resetLoginError
} from "../../redux/actions/userActions"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import fitpeoLogo from "../../assets/fitpeo_logo.png"
import patientImg from "../../assets/login-patient.jpg"

import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import LoginComponent from "./Login"
import OTPForm from "../ForgotPassword/OTPForm"
import ChangePasswordForm from "../ForgotPassword/ChangePasswordForm"
import { Mixpanel } from "../../utils/mixpanel"
import { GoogleLogin } from "@react-oauth/google"
import FloatInput from "../../utils/FloatInput/FloatInput"
import { UserOutlined } from "@ant-design/icons"

const INVALID_CREDS = "Enter a Valid Email or Phone Number"
import ConfirmSignup from "./ConfirmSignup"
import { useEffect } from "react"
import { NextArrow, PrevArrow } from "./CarouselArrow"

const schema = yup
  .object({
    credential: yup
      .string()
      .required(INVALID_CREDS)
      .test("value-test", INVALID_CREDS, function (value) {
        const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
        const phoneRegex = /^\(?[2-9]\d{2}\)?[-.]?\d{3}[-.]?\d{4}$/
        let isValidEmail = emailRegex.test(value)
        let isValidPhone = phoneRegex.test(value)
        if (!isValidEmail && !isValidPhone) {
          return false
        }
        return true
      })
  })
  .required()

const defaultValues = {
  credential: ""
}

const LoginHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [userName, setUserName] = useState("")
  const [showNext, setShowNext] = useState(false)
  const [otpValue, setOtpValue] = useState("")
  const [showOTPForm, setShowOTPForm] = useState(false)
  const [showConfirmSignup, setShowConfirmSignup] = useState(false)
  const [changePasswordShow, setChangePasswordShow] = useState(false)

  const { loading: validateLoading, error: validateError } = useSelector(
    state => state.validateLoginDetails
  )
  const { loading: googleLoginLoading, error: googleLoginErr } = useSelector(
    state => state.googleLogin
  )

  useEffect(() => {
    dispatch(resetLoginError())
  }, [])

  window.onpopstate = () => {
    if (showNext) setShowNext(false)
  }

  useEffect(() => {
    if (location?.state?.credential) {
      setUserName(location.state.credential)
      setShowNext(true)
    }
  }, [location])

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })
  const credentialWatcher = watch("credential")

  const onSubmit = async ({ credential }) => {
    setUserName(credential)

    // removing the previous localstroage
    localStorage.removeItem("token")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("id")
    localStorage.removeItem("welcomeTutorial")
    localStorage.removeItem("userInfo")

    let res = await dispatch(validateLoginDetailsAction(credential))
    // res?.details?.forgotOTP === false // removed below from if condition (bug-FIT-5534)
    if (res?.details?.onBoardingOTP === false && res?.details?.setPassword === false) {
      setShowNext(true)
    }
    if (res?.details?.onBoardingOTP === true || res?.details?.setPassword === true) {
      setShowConfirmSignup(true)
    }
  }

  const onSuccessGoogleLogin = ({ credential }) =>
    dispatch(googleLoginAction({ credential, navigateCallback: navigate }))

  const onConfirmSignup = async () => {
    let res = await dispatch(confirmSignupAction(userName))
    if (res?.details?.onBoardingOTP === true || res?.details?.setPassword === true) {
      setShowConfirmSignup(false)
      setShowOTPForm(true)
    }
  }

  return (
    <>
      {!showOTPForm ? (
        <Row align="middle" className="w-full h-screen flex flex-row-reverse">
          <Col
            xs={24}
            lg={12}
            className="relative px-4 h-full flex flex-col justify-center items-center">
            <img
              alt="logo"
              src={fitpeoLogo}
              className="absolute top-6 md:top-3 md:right-7 w-[9vw]"
            />
            <div className="max-w-2xl w-full md:px-24">
              {changePasswordShow ? (
                <>
                  <h1 className="font-semibold text-xl leading-none">Create Password</h1>
                  <h2 className="mt-3 mb-4 text-secondary leading-none">
                    Set a password for future access to your account
                  </h2>
                </>
              ) : (
                <>
                  <h1 className="font-semibold text-xl leading-none">Log in to FitPeo</h1>
                  <h2 className="mt-3 mb-4 text-secondary leading-none">
                    Enter Email Address or Phone Number
                  </h2>
                </>
              )}
              {showNext ? (
                <LoginComponent userName={userName} />
              ) : changePasswordShow ? (
                <ChangePasswordForm
                  otp={otpValue}
                  userName={userName}
                  setChangePasswordShow={setChangePasswordShow}
                />
              ) : (
                <>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-1 min-h-[65px]">
                      <Controller
                        name="credential"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FloatInput label="Email or Phone" placeholder="Email or Phone Number">
                            <Input
                              className="h-[48px]"
                              prefix={<UserOutlined className="text-[#96A0B5]" />}
                              status={errors.credential ? "error" : undefined}
                              {...field}
                              onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                            />
                          </FloatInput>
                        )}
                      />

                      {errors.credential ? (
                        <small className="text-danger">{errors.credential.message}</small>
                      ) : null}
                      {validateError !== undefined && typeof validateError === "string" && (
                        <NavLink
                          className="text-primary float-right underline"
                          to="/identify-patient"
                          state={{ enteredCred: userName }}>
                          Forgot email or phone?
                        </NavLink>
                      )}
                    </div>

                    <Button
                      block
                      className="h-[48px]"
                      type="primary"
                      htmlType="submit"
                      disabled={Boolean(validateLoading) || !credentialWatcher?.length}
                      onClick={() => {
                        Mixpanel.track("Login Validate")
                      }}>
                      {validateLoading ? "Loading..." : "Next"}
                    </Button>

                    {validateError !== undefined && typeof validateError === "string" && (
                      <p className="mt-3 text-danger text-center font-medium">{validateError}</p>
                    )}
                  </form>
                </>
              )}
              {showConfirmSignup ? (
                <ConfirmSignup
                  onConfirmSignup={onConfirmSignup}
                  showConfirmSignup={showConfirmSignup}
                />
              ) : null}

              {!changePasswordShow ? (
                <>
                  <Divider plain>
                    <span className="text-secondary text-xs">Or</span>
                  </Divider>

                  <Spin spinning={googleLoginLoading === true}>
                    <div className="mt-4 mb-3 grid place-content-center">
                      <GoogleLogin
                        onSuccess={onSuccessGoogleLogin}
                        onError={() => {
                          console.log("Login Failed")
                        }}
                      />
                    </div>
                  </Spin>

                  {googleLoginErr !== undefined && typeof googleLoginErr === "string" && (
                    <p className="text-danger text-center font-medium">{googleLoginErr}</p>
                  )}
                  <p>
                    By signing up, you agree to our{" "}
                    <a
                      href="https://fitpeo.com/user-agreement"
                      className="text-primary"
                      aria-label="Terms and Conditions"
                      target="_blank"
                      rel="noopener noreferrer">
                      {" "}
                      Terms & Conditions{" "}
                    </a>{" "}
                    and agree to our{" "}
                    <a
                      href="https://fitpeo.com/privacy-policy"
                      className="text-primary"
                      aria-label="Privacy Policy"
                      target="_blank"
                      rel="noopener noreferrer">
                      {" "}
                      Privacy Policy{" "}
                    </a>
                    .
                  </p>
                </>
              ) : null}
            </div>
          </Col>
          <Col xs={0} lg={12} className="h-full">
            <div className="relative h-full flex items-center flex-col-reverse">
              <div
                className="absolute inset-0 bg-cover bg-center rounded-xl overflow-hidden my-3 ml-4"
                style={{ backgroundImage: `url(${patientImg})` }}>
                <div className="absolute inset-0 bg-black/50 backdrop-blur-lg rounded-xl" />
              </div>

              {/* Square Image */}
              <div className="absolute flex items-center justify-center h-full px-8 py-3 pl-12">
                <img src={patientImg} alt="patient-login" className="object-cover h-full" />
              </div>
              <div className="w-full">
                <Carousel
                  autoplay
                  arrows
                  // dots={false}
                  className="h-[400px] ml-4 mb-3 pt-32"
                  style={{
                    borderRadius: "12px",
                    color: "#FFF",
                    background:
                      "linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center"
                  }}
                  prevArrow={<PrevArrow />}
                  nextArrow={<NextArrow />}>
                  <div>
                    <h2 className="text-2xl font-semibold">
                      Gaining insight into patient health from afar
                    </h2>
                    <h3 className="text-base mt-2 px-2">
                      This technology empowers both patients and clinicians, leading to more
                      personalized care and better health outcomes.
                    </h3>
                  </div>
                  <div>
                    <h2 className="text-2xl font-semibold">
                      Generate tailored reports for detailed health analysis.
                    </h2>
                    <h3 className="text-base mt-2 px-2">
                      Access a comprehensive snapshot of patient health, including key vitals and
                      recent activity updates.
                    </h3>
                  </div>
                  <div>
                    <h2 className="text-2xl font-semibold">
                      Track adherence to prescribed medication schedules.
                    </h2>
                    <h3 className="text-base mt-2  px-2">
                      Ensure patients are following their prescribed medication schedules with
                      real-time tracking and reminders.
                    </h3>
                  </div>
                </Carousel>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <OTPForm
          credential={userName}
          setOtpValue={setOtpValue}
          setShowNext={setShowOTPForm}
          setChangePasswordShow={setChangePasswordShow}
        />
      )}
    </>
  )
}

export default LoginHome