import {
  CGM_DEXCOM_CODE_FAIL,
  CGM_DEXCOM_CODE_REQUEST,
  CGM_DEXCOM_CODE_SUCCESS
} from "../types/cgmConstant"

export const cgmDexcomcodeReducer = (state = {}, action) => {
  switch (action.type) {
    case CGM_DEXCOM_CODE_REQUEST:
      return { loading: true }
    case CGM_DEXCOM_CODE_SUCCESS:
      return { loading: false, cgmDexcomCode: action.payload }
    case CGM_DEXCOM_CODE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
