import React, { useEffect, useState } from "react"
// import swal from "sweetalert"
import { Card, Row, Col, Avatar } from "antd"
// import { UserOutlined, PhoneOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import {
  // postCallRequestAction,
  // getPatientCaretaker,
  getLatestReadings
} from "../../redux/actions/patientAction"
import "./dashboard.css"
import { NavLink } from "react-router-dom"
import { Mixpanel } from "../../utils/mixpanel"
import { getEquipmentStatusAction } from "../../redux/actions/equipmentStatusAction"
import EquipmentFunctionStatus from "./EquipmentFunctionStatus"

const BP = "blood pressure machine"
const GM = "glucose machine"
const OXI = "oximeter"
const WM = "weight machine"
const CGM = "cgm (continuous glucose machine)"

const PatientDashboard = ({ userInfo }) => {
  const [latestReadings, setLatestReadings] = useState({})
  const [allotedDevices, setAllotedDevices] = useState({})
  const [showEquipmentFunctionModal, setShowEquipmentFunctionModal] = useState(false)

  const dispatch = useDispatch()

  const { error } = useSelector(store => store.userCallRequest)
  // const { patientCaretaker, loading: caretakerLoading } = useSelector(state => state.patientCaretaker)
  const { patientLatestReading } = useSelector(state => state.patientLatestReading)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)
  const {
    getEquipmentStatus,
    loading: equipmentStatusLoading,
    error: equipmentStatusError
  } = useSelector(state => state.getEquipmentStatus)

  let rpmDevices = []
  useEffect(() => {
    if (!userInfo?.id || !patientMonitoringData?.rpm) return
    // dispatch(getPatientCaretaker(userInfo?.id))
    dispatch(getLatestReadings(userInfo?.id))
    patientMonitoringData?.rpm?.map(({ programs }) =>
      programs?.map(item => rpmDevices.push(item?.programName))
    )
    setAllotedDevices(rpmDevices)
  }, [userInfo, patientMonitoringData])

  useEffect(() => {
    if (!patientLatestReading) return
    // convert latest reading array to object
    let readings = {}
    patientLatestReading?.forEach(ele => {
      readings = { ...readings, ...ele }
    })
    setLatestReadings(readings)
  }, [patientLatestReading])

  useEffect(() => {
    dispatch(getEquipmentStatusAction(userInfo?.id))
  }, [])

  useEffect(() => {
    if (getEquipmentStatus && getEquipmentStatus?.status?.toLowerCase()?.trim() === "delivered") {
      setShowEquipmentFunctionModal(true)
    } else {
      setShowEquipmentFunctionModal(false)
    }
  }, [equipmentStatusLoading])

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      var intlCode = match[1] ? "+1 " : ""
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
    }
    return "Not Assigned"
  }
  // const handleSubmit = async (type, id) => {
  //   // console.log('type',type,'id',id)
  //   if (!userInfo?.phoneNumber || !userInfo?.email)
  //     return swal("Alert",  "Phone number and Email is mandatory, Please add them from setting section", "warning")
  //   const newData = {
  //     userName: userInfo?.userName,
  //     userEmail: userInfo?.email,
  //     phoneNumber: userInfo?.phoneNumber,
  //     adminId: id,
  //     callType: type
  //   }
  //   const res = await dispatch(postCallRequestAction(newData))
  //   if (res?.success) {
  //     swal("Call Placed Successfully", {
  //       icon: "success"
  //     })
  //   } else {
  //     swal("Oops!", "Unable to place call request.", "error")
  //   }
  // }
  // const handleCall = (type, id) => {
  //   swal({
  //     title: "Please Confirm",
  //     text: `Do you want to place ${type} call request ?`,
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true
  //   }).then(willDelete => {
  //     if (willDelete) {
  //       handleSubmit(type, id)
  //     } else {
  //       swal("Call Cancelled")
  //     }
  //   })
  // }

  return (
    <>
      <Row gutter={16}>
        <Col sm={24} xl={12} className="mb-4 w-full">
          <Card title="" className="welcome-card mb-4">
            <div className="flex gap-4">
              <div className="text-wrapper">
                <h4 className="text-2xl mb-2">
                  Hello,{" "}
                  <span className="text-current capitalize">
                    {userInfo?.firstName} {userInfo?.lastName}
                  </span>
                </h4>
                <p>
                  Have a nice day and don't forget to <br /> take care of your health.
                </p>
              </div>
              <div className="illustration-wrapper">
                <img alt="doctors-illustration" src="/assets/doctors-illustration.png" />{" "}
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} xl={12} className="mb-4 w-full">
          <Card title="">
            <div className="flex items-center flex-col sm:flex-row gap-4">
              <div>
                <img className="img-fluid" alt="user-icon" width={80} src="/assets/user-icon.png" />
              </div>
              <div className="sm:border-s-2  border-current pl-0 sm:pl-5">
                <div>
                  <div className="mb-0 flex">
                    <span className="min-w-[60px]">Email:</span>{" "}
                    <span className="font-medium">{userInfo?.email}</span>
                  </div>
                  <div className="my-1 flex gap-1">
                    <span className="min-w-[60px]">Gender at the time of birth: </span>{" "}
                    <span className="font-medium">
                      {userInfo?.gender.charAt(0).toUpperCase() + userInfo?.gender.slice(1)}
                    </span>
                  </div>
                  <div className="mb-0 flex">
                    <span className="min-w-[60px]">Contact:</span>{" "}
                    <span className="font-medium">{formatPhoneNumber(userInfo?.mobileNumber)}</span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>

        {allotedDevices.length && (
          <>
            {allotedDevices.includes(BP) && (latestReadings[BP] || latestReadings[BP] === null) ? (
              <Col sm={24} md={8} xl={4} className="mb-4 w-full">
                <NavLink
                  to="/vitals/blood-pressure"
                  onClick={() => Mixpanel.track("Blood Pressure Clicked")}>
                  <Card title="" hoverable>
                    <div className="flex items-center gap-3 mb-3">
                      <Avatar
                        size={30}
                        src={
                          <img src="/assets/blood-pressure-icon.png" alt="blood-pressure-icon" />
                        }
                      />
                      {latestReadings[BP]?.systolicPressure &&
                      latestReadings[BP]?.diastolicPressure ? (
                        <h5 className="text-xl">
                          {latestReadings[BP].systolicPressure}/
                          {latestReadings[BP].diastolicPressure}
                        </h5>
                      ) : (
                        <h6 className="font-medium">No Readings</h6>
                      )}
                    </div>
                    <h5 className="text-lg mb-1">Blood Pressure</h5>
                    <p>A normal blood pressure level is less than 120/80 mmHg.</p>
                  </Card>
                </NavLink>
              </Col>
            ) : null}

            {allotedDevices.includes(OXI) &&
            (latestReadings[OXI] || latestReadings[OXI] === null) ? (
              <Col sm={24} md={8} xl={4} className="mb-4 w-full">
                <NavLink
                  to="/vitals/oximeter"
                  onClick={() => Mixpanel.track("Oximeter Readings Clicked")}>
                  <Card title="" hoverable>
                    <div className="flex items-center gap-3 mb-3">
                      <Avatar
                        size={30}
                        src={<img src="/assets/heart-with-pulse.png" alt="heart-with-pulse" />}
                      />
                      {latestReadings[OXI]?.pulseRate ? (
                        <h5 className="text-xl">{latestReadings[OXI].pulseRate}</h5>
                      ) : (
                        <h6 className="font-medium">No Readings</h6>
                      )}
                    </div>
                    <h5 className="text-lg mb-1">Heart Rate</h5>
                    <p>A normal BPM should be between 60 to 100 beats per minute.</p>
                  </Card>
                </NavLink>
              </Col>
            ) : null}

            {allotedDevices.includes(OXI) &&
            (latestReadings[OXI] || latestReadings[OXI] === null) ? (
              <Col sm={24} md={8} xl={4} className="mb-4 w-full">
                <NavLink
                  to="/vitals/oximeter"
                  onClick={() => Mixpanel.track("Oximeter Readings Clicked")}>
                  <Card title="" hoverable>
                    <div className="flex items-center gap-3 mb-3">
                      <Avatar
                        size={30}
                        src={<img src="/assets/oxygen-icon.png" alt="oxygen-icon" />}
                      />
                      {latestReadings[OXI]?.spo ? (
                        <h5 className="text-xl">{latestReadings[OXI].spo}</h5>
                      ) : (
                        <h6 className="font-medium">No Readings</h6>
                      )}
                    </div>
                    <h5 className="text-lg mb-1">Oxygen</h5>
                    <p>A normal Oxygen Level should be between 94 or above.</p>
                  </Card>
                </NavLink>
              </Col>
            ) : null}

            {allotedDevices.includes(GM) || allotedDevices.includes(CGM) ? (
              <Col sm={24} md={8} xl={4} className="mb-4 w-full">
                <NavLink
                  to={allotedDevices.includes(CGM) ? `/vitals/cgm` : `/vitals/glucose`}
                  onClick={() => Mixpanel.track("Glucose Readings Clicked")}>
                  <Card title="" hoverable>
                    <div className="flex items-center gap-3 mb-3">
                      <Avatar
                        size={30}
                        className="overflow-visible"
                        src={
                          <img
                            className="img-fluid"
                            src="/assets/glucose-icon.png"
                            alt="glucose-icon"
                          />
                        }
                      />
                      {latestReadings[GM]?.glucoseLevel ? (
                        <h5 className="text-xl">{latestReadings[GM].glucoseLevel}</h5>
                      ) : (
                        <h6 className="font-medium">No Readings</h6>
                      )}
                    </div>
                    <h5 className="text-lg mb-1">Glucose</h5>
                    <p>A blood sugar level less than 140 mg/dL is normal. </p>
                  </Card>
                </NavLink>
              </Col>
            ) : null}

            {allotedDevices.includes(WM) && (latestReadings[WM] || latestReadings[WM] === null) ? (
              <Col sm={24} md={8} xl={4} className="mb-4 w-full">
                <NavLink
                  to="/vitals/weight-machine"
                  onClick={() => Mixpanel.track("Weight Machine Clicked")}>
                  <Card title="" hoverable>
                    <div className="flex items-center gap-3 mb-3">
                      <Avatar
                        size={30}
                        className="overflow-visible"
                        src={<img src="/assets/weight-icon.png" alt="weight-icon" />}
                      />
                      {latestReadings[WM]?.weight ? (
                        <h5 className="text-xl">{latestReadings[WM].weight}</h5>
                      ) : (
                        <h6 className="font-medium">No Readings</h6>
                      )}
                    </div>
                    <h5 className="text-lg mb-1">Weight</h5>
                    <p>
                      {latestReadings[WM]?.bmi && (
                        <span>
                          Your BMI is <span className="font-medium">{latestReadings[WM]?.bmi}</span>{" "}
                          .{" "}
                        </span>
                      )}
                      A normal BMI should be between 18.5 and 24.9.
                    </p>
                  </Card>
                </NavLink>
              </Col>
            ) : null}
          </>
        )}

        {/* <Col sm={24} xl={12} className="w-full">
        <Card title="Care Takers">
          <List
            itemLayout="horizontal"
            dataSource={patientCaretaker}
            loading={caretakerLoading}
            renderItem={caretakerData => (
              <List.Item
                actions={[
                  <Button
                    shape="circle"
                    icon={<PhoneOutlined rotate={90} />}
                    className="flex items-center justify-center"
                    onClick={() =>
                      handleCall(
                        "voice",
                        userInfo?.moniteringDetails?.[0]?.adminDetails?.adminData?.userId
                      )
                    }
                  />
                ]}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      size={40}
                      icon={<UserOutlined />}
                      className="flex items-center justify-center"
                    />
                  }
                  description={
                    <p className="text-secondary">{caretakerData?.mobileNumber || "No Contact"}</p>
                  }
                  title={caretakerData?.userName}
                />
              </List.Item>
            )}
          />
        </Card>
      </Col> */}
      </Row>
      <EquipmentFunctionStatus
        setShowEquipmentFunctionModal={setShowEquipmentFunctionModal}
        showEquipmentFunctionModal={showEquipmentFunctionModal}
      />
    </>
  )
}
export default PatientDashboard
