
export default async function getCroppedImg(imageSrc, crop) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    return null
  }

  const { width: cropWidth, height: cropHeight, x, y } = crop

  canvas.width = cropWidth
  canvas.height = cropHeight

  ctx.drawImage(image, x, y, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight)

  return await new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      if (file) {
        resolve(URL.createObjectURL(file))
      } else {
        reject(new Error("Canvas is empty"))
      }
    }, "image/jpeg")
  })
}

async function createImage(url) {
  return await new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener("load", () => resolve(image))
    image.addEventListener("error", error => reject(error))
    image.setAttribute("crossOrigin", "anonymous") // needed to avoid cross-origin issues
    image.src = url
  })
}
